import { tns } from '../../node_modules/tiny-slider/src/tiny-slider';
import ScrollReveal from 'scrollreveal'

if( document.querySelector('.similar-case-studies-slider')) {
  var servicesSlider = tns({
    container: '.similar-case-studies-slider',
    items: 1,
    slideBy: 'page',
    autoplay: true,
    gutter: 36,
    autoplayButtonOutput: false,
    controlsText: ['<i class="fal fa-chevron-left"></i>', '<i class="fal fa-chevron-right"></i>'],
    responsive: {
      600: {										
        items: 2
      },				
      1024: {										
        items: 3
      }
    }
  });
}

if( document.querySelector('.services-slider')) {
  var servicesSlider = tns({
    container: '.services-slider',
    items: 1,
    slideBy: 'page',
    autoplay: true,
    gutter: 36,
    autoplayButtonOutput: false,
    controlsText: ['<i class="fal fa-chevron-left"></i>', '<i class="fal fa-chevron-right"></i>'],
    responsive: {
      600: {										
        items: 2
      },				
      1024: {										
        items: 3
      }
    }
  });
}

if( document.querySelector('.case-study-slider')) {
  var testimonialSlider = tns({
    container: '.case-study-slider',
    items: 3,
    center: true,
    autoplay: true,
    autoplayButtonOutput: false,
    gutter: 9,
    nav:false,
    controls:true,
    controlsText: ['<i class="fal fa-chevron-left"></i>', '<i class="fal fa-chevron-right"></i>'],
    responsive: {
      600: {										
        items: 3
      },				
      1024: {										
        items: 4
      }
    }
  });  
}

if( document.querySelector('.testimonial-slider')) {
  var testimonialSlider = tns({
    container: '.testimonial-slider',
    mode: 'gallery',
    items: 1,
    slideBy: 'page',
    autoplay: true,
    autoplayButtonOutput: false,
    nav:false,
    controls:false,
    controlsText: ['<i class="fal fa-chevron-left"></i>', '<i class="fal fa-chevron-right"></i>']
  });  
}

if( document.querySelector('#instagram-feed')) {
  (function(){
    new InstagramFeed({
      'username': 'grhsolutions',
      'container': document.getElementById('instagram-feed'),
      'display_profile': false,
      'display_biography': false,
      'display_gallery': true,
      'callback': null,
      'styling': false,
      'items': 6,
    });
  })();
}

ScrollReveal().reveal('.reveal', { 
  delay: 200 
});

ScrollReveal().reveal('.reveal--from-bottom', { 
  delay: 150,
  reset: true,
  distance: '10%',
  origin: 'bottom',
});

var bannerImages = document.querySelectorAll('.banner img');
new simpleParallax(bannerImages);

var serviceImages = document.querySelectorAll('.service img');
new simpleParallax(serviceImages,  {
	delay: .6,
	transition: 'cubic-bezier(0,0,0,1)'
});